import React from 'react';
import Article from '../../components/project/Project';
import { projectphoto02, projectphoto06, projectphoto04, linetransition } from './imports';
import './projects.css';

const Projects = () => (
  <div className="projects section__padding" id="projects">
    <img className="line-transition" src={linetransition} alt="" />
    <div className="projects-heading">
      <h2>Projects<span className="color-period">.</span></h2>
    </div>
    <div className="projects-container">
      <div className="projects-container_groupB">
      <Article imgUrl={projectphoto04} title="Dodo Codes Live Chat" projectUrl="https://dodocodes.vercel.app/" text="An Animal Crossing inspired React App using a Google Firebase database, Google login, and censorship for users' submissions" />
      <Article imgUrl={projectphoto02} title="Task App" projectUrl="https://task-vault.vercel.app/" text="A React App using the Material UI library and demonstrating full functionality and animations." />
      <Article imgUrl={projectphoto06} title="A Business Website" projectUrl="https://nailsbytam.com" text="A manicurist website using Wordpress that’s equipped with WooCommerce, Elementor, and analytics" />
      </div>
    </div>
    
  </div>
);

export default Projects;
