import React from 'react';
import aboutImage from '../../assets/about-me-photo.jpg';
import linetransition from '../../assets/line-transition.png';
import './about.css';

const About = () => (
  <div className="about section__padding" id="about">
    <img className="line-transition about" src={linetransition} alt="" />
    <div className="about-content">
      <h2>About<span className="color-period">.</span></h2>
      <p>From an entrepreneur to a full-fledged web developer, I thrive in designing and building pixel-perfect web existences from square one. Consistently learning to solve problems combined with the feeling of accomplishment is what I most love about coding.</p>
      <p>The vital soft skills I’ve gained from my distinct career experience, the art foundation from my Fine Arts BA, and keen organization/attention to detail are all channeled into making the web a better place. There is always something to discover in tech and my career fulfills my curiosity.</p>
      <p>I’m an advocate for accuracy, optimism, and stability (hence why I am a CS on the DiSC scale). I believe the journey of any goal is just as enjoyable as the actual destination. As it relates to my career, I thoroughly enjoy the perseverance it takes to be challenged continuously and overcome.</p>

    </div>
    <div className="about-image">
      <img src={aboutImage} alt="about" />
    </div>
  </div>
);

export default About;
